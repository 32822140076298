import type { StandardTextFieldProps } from '@mui/material';
import { Stack, TextField } from '@mui/material';

import type { CalendarPickerView } from '@mui/x-date-pickers';
import { DatePicker } from '@mui/x-date-pickers';
import type { FormikProps } from 'formik';
import { Button } from './Button';
import isSameDay from 'date-fns/isSameDay';
import subDays from 'date-fns/subDays';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { isBefore, startOfDay } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';
import { useLocale } from '@pflegenavi/frontend/localization';

interface IProps<T extends string, V> extends StandardTextFieldProps {
  id?: string;
  name: T;
  formik: FormikProps<V>;
  minDate?: Date;
  maxDate?: Date;
  placeholder?: string;
  dataCy?: string;
  disableTyping?: boolean;
  openTo?: CalendarPickerView;
}

export const FDatePicker = <
  Field extends string,
  V extends { [key in Field]?: Date }
>({
  name,
  formik,
  minDate,
  maxDate,
  placeholder,
  label,
  disabled,
  dataCy,
  disableTyping,
  openTo = 'day',
}: IProps<Field, V>): JSX.Element => {
  const isValidDate = (d: any) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return d instanceof Date && !isNaN(d);
  };

  const locale = useLocale();

  const dateFormat = useMemo(
    () => (locale === 'de-AT' ? { inputFormat: 'dd.MM.yyyy' } : null),
    [locale]
  );

  const helperText = formik.touched[name] && formik.errors[name];

  return (
    <DatePicker
      // TODO: Replace hardcoded mask with locale specific mask
      mask={locale === 'de-AT' ? '__.__.____' : '__/__/____'}
      {...dateFormat}
      value={formik.values[name] ?? null}
      onChange={(selectedDate) => {
        if (isValidDate(selectedDate)) {
          formik.setFieldValue(
            name,
            zonedTimeToUtc(startOfDay(selectedDate ?? new Date()), 'UTC')
          );
        } else {
          formik.setFieldValue(name, selectedDate);
        }
        formik.setFieldError(name, undefined);
      }}
      disabled={disabled || formik.isSubmitting}
      minDate={minDate}
      maxDate={maxDate}
      openTo={openTo}
      renderInput={(params) => {
        const inputProps = placeholder
          ? { ...params.inputProps, placeholder }
          : params.inputProps;

        return (
          <TextField
            {...params}
            data-cy={dataCy}
            fullWidth
            label={label}
            onBlur={() => {
              formik.setFieldTouched(name);
            }}
            name={name}
            error={Boolean(formik.errors[name] && formik.touched[name])}
            helperText={typeof helperText === 'string' ? helperText : ''}
            inputProps={inputProps}
            onKeyDown={(e) => {
              if (disableTyping) {
                e.preventDefault();
              }
            }}
            sx={
              disableTyping
                ? {
                    userSelect: 'none',
                    pointerEvents: 'none',
                    '& .MuiInputAdornment-root': {
                      pointerEvents: 'auto',
                    },
                  }
                : {}
            }
          />
        );
      }}
    />
  );
};

export const FDatePickerQuickActions = <
  Field extends string,
  V extends { [key in Field]?: Date }
>({
  name,
  formik,
  minDate,
  disabled,
}: IProps<Field, V>): JSX.Element | null => {
  const value = formik.values[name];

  const { setFieldValue } = formik;

  const setToday = useCallback(
    (date: Date) => {
      setFieldValue(name, date);
    },
    [setFieldValue, name]
  );

  const setYesterday = useCallback(
    (date: Date) => {
      setFieldValue(name, date);
    },
    [setFieldValue, name]
  );

  return (
    <DatePickerQuickActions
      name={name}
      value={value}
      setToday={setToday}
      setYesterday={setYesterday}
      minDate={minDate}
      disabled={disabled}
    />
  );
};

// eslint-disable-next-line complexity
export const DatePickerQuickActions = ({
  name,
  value,
  disabled,
  setToday,
  setYesterday,
  minDate,
}: {
  name: string;
  value?: Date;
  disabled?: boolean;
  setToday: (date: Date) => void;
  setYesterday: (date: Date) => void;
  minDate?: Date;
}): JSX.Element | null => {
  const { t } = useTranslation();

  const getToday = () => zonedTimeToUtc(startOfDay(new Date()), 'UTC');
  const getYesterday = useCallback(() => subDays(getToday(), 1), []);
  const todayActive = value && isSameDay(value, getToday());
  const yesterdayActive = value && isSameDay(value, getYesterday());

  const setTodayCallback = useCallback(() => {
    setToday(getToday());
  }, [setToday]);

  const setYesterdayCallback = useCallback(() => {
    setYesterday(getYesterday());
  }, [setYesterday, getYesterday]);

  if (minDate && isBefore(getToday(), minDate)) {
    return null;
  }

  return (
    <Stack direction="row" gap={0.5}>
      <Button
        name={name + 'QuickActionsToday'}
        tabIndex={-1}
        variant={
          todayActive ? (disabled ? 'outlined' : 'contained') : 'outlined'
        }
        color="primary"
        size="small"
        onClick={setTodayCallback}
        disabled={disabled}
      >
        {t('datePicker.today')}
      </Button>
      {(!minDate || !isBefore(getYesterday(), minDate)) && (
        <Button
          name={name + 'QuickActionsTomorrow'}
          tabIndex={-1}
          variant={
            yesterdayActive ? (disabled ? 'outlined' : 'contained') : 'outlined'
          }
          color="primary"
          size="small"
          onClick={setYesterdayCallback}
          disabled={disabled}
        >
          {t('datePicker.yesterday')}
        </Button>
      )}
    </Stack>
  );
};
